<script setup>
/* eslint-disable */
  import { ElMessage } from 'element-plus'
  import { useStore } from 'vuex'
  import { useRouter } from "vue-router";
  import { ref, reactive, computed, watchEffect, defineEmits, defineProps } from 'vue';
  import Bus from "../js/Bus";
  import axios from 'axios'

  const show = computed({
    get () {
      return store.state.showUpdateApplicant
    },
    set (val) {
      store.commit('updateShowApplicant', val)
    }
  })

  defineProps(['name'])

  const emit = defineEmits(['submit'])

  const router = useRouter()
  const store = useStore()
  const btnLoading = ref(false)

  const beforeClose = (done) => {
    store.commit('updateShowApplicant', false)
    done()
  } 

  const cancel = () => {
    store.commit('updateShowApplicant', false)
  }

  const subType = ref(1)

  const submit = () => {
    emit('submit')
  }

  watchEffect(() => {
    if (show.value) {
    }
  })
</script>

<template>
  <div class="update-applicant">
    <el-dialog v-model="show"
      style="width: 490px"
      :show-close="false"
      :beforeClose="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
      <div class="update-body">
        <div class="update-header">
          更新著作权人
        </div>
        <div class="update-center">
          <div class="center-title center-title1">检测到您填写的著作权人与授权账号不一致</div>
          <div class="center-title">是否将著作权人更新为当前授权账号（<span>{{name}}</span>）</div>
          <div class="center-title center-title2">变更著作权人会重新整理材料，整理完成后请重新提交至版权中心</div>
        </div>
        <div class="update-btns">
          <el-button class="cancelBtn" @click="cancel">取消</el-button>
          <el-button type="primary" :loading="btnLoading" :disabled="btnLoading" class="subBtn" @click="submit">确定并更新著作权人</el-button>
        </div>
      </div>
      <template #header>
        <div class="dialog-header">
          
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer">
        
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.update-applicant {
  :deep(.el-dialog) {
    background: transparent;
    box-shadow: none;
    .el-dialog__header {
      padding: 0;
      margin: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer {
      padding: 0;
    }
    .update-body {
      background: #FFF;
      border-radius: 8px;
      .update-header {
        height: 55px;
        line-height: 55px;
        padding:  0 24px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 18px;
        color: #1A1A1A;
        border-bottom: 1px solid #D8D8D8;
        text-align: left;
      }
      .update-center {
        padding: 33px 24px 47px;
        text-align: left;
        border-bottom: 1px solid #D8D8D8;
        .center-title {
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          font-size: 14px;
          color: #1A1A1A;
          line-height: 23px;
        }
        .center-title2 {
          color: #FA5151;
          margin-top: 13px;
        }
      }
      .update-btns {
        height: 77px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 17px;
        .subBtn {
          width: 167px;
          height: 31px;
          background: #6196FF;
          border-radius: 50px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
        }
        .cancelBtn {
          width: 100px;
          height: 31px;
          border-radius: 50px;
          border: 1px solid #6196FF;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          font-size: 14px;
          color: #6196FF;
          box-sizing: border-box;
        }
      }
    }
  }
}

</style>