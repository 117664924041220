<script setup>
/* eslint-disable */
  import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'
  import { useStore } from 'vuex'
  import { useRoute, useRouter } from "vue-router";
  import { ref, reactive, computed, watchEffect } from 'vue';
  import Bus from "../js/Bus";
  const props = defineProps(["modelValue", "data"]);
  const emits = defineEmits(["update:modelValue", "submit", "cancel"]);

  const show = computed({
    get() {
      return props.modelValue;
    },
    set(val) {
      emits("update:modelValue", val);
    }
  });

  const userInfo = computed({
    get () {
      return store.state.userInfo
    },
    set (val) {
      store.commit('updateUserInfo', val)
    }
  })

  const route = useRoute()
  const router = useRouter()
  const store = useStore()

  const beforeClose = done => {
    done();
  };

  const cancel = () => {
    show.value = false;
    emits("cancel");
  };

  const submit = () => {
    if (checked.value) {
      localStorage.setItem(type.value + '_' + userInfo.value.id, userInfo.value.id)
    }
    let params = {
      type: type.value,
      status: status.value,
      show: showPackage.value
    }
    emits("submit", params);
  }

  const checked = ref(false)
  const title = ref('')
  const desc = ref('')
  const showPackage = ref(false)
  const type = ref('')
  const status = ref(0)


  watchEffect(() => {
    if (show.value) {
      const { data } = props
      console.log(props);
      title.value = data.title
      desc.value = data.desc
      status.value = data.status
      type.value = data.type
      showPackage.value = data.show
    }
  })
</script>

<template>
  <div class="checkPackage">
    <el-dialog v-model="show"
      style="width: 486px"
      :show-close="false"
      :beforeClose="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="true">
      <div class="dialog-body">
        <div class="title">{{title}}</div>
        <div class="desc">{{desc}}</div>
      </div>

      <template #header>
        <div class="dialog-header">
          <div>提示</div>
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <div>
            <div v-if="status === 1" class="check-item"><el-checkbox v-model="checked" label="不再提醒" size="large" /></div>
          </div>
          <div class="flex-end">
            <div class="add_cancel" @click="cancel">取消</div>
            <div class="add_submit" @click="submit">{{ status === 1 ? '确定': '购买'}}</div>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.checkPackage {
  :deep(.el-dialog) {
    .el-dialog__header {
      padding: 0;
      margin: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer {
      padding: 0;
    }
    .dialog-header {
      height: 50px;
      padding: 0 24px;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #999;
    }
    .dialog-body {
      padding: 32px 24px 48px;
      .title {
        text-align: left;
      }
      .desc {
        margin-top: 16px;
        text-align: left;
        color: #999;
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-top: 1px solid #999;
      .add_submit {
        width: 100px;
        height: 31px;
        line-height: 31px;
        background: #6196FF;
        border-radius: 50px;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        cursor: pointer;
        margin-left: 15px;
      }
      .add_cancel {
        width: 100px;
        height: 29px;
        line-height: 29px;
        border-radius: 50px 50px 50px 50px;
        opacity: 1;
        border: 1px solid #6196FF;
        font-size: 16px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        color: #6196FF;
        margin-left: 16px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
</style>