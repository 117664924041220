<template>
  <router-view/>
  <!-- <router-view>
    <template #default="{Component, route}">
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" :key="route.fullPath"></component>
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" :key="route.fullPath"></component>
    </template>
  </router-view> -->
  <!-- <router-view v-slot="{ Component, route }">
    <keep-alive>
      <component :is="Component" :key="route.name"></component>
    </keep-alive>
  </router-view> -->
  <div id="captcha"></div>
  <SoftDialog></SoftDialog>
  <Question v-if="route.path !== '/login' && route.path !== '/register' && route.path !== '/404' && route.path !== '/printingMaterials'"></Question>
  <FeedBack></FeedBack>
  <CaptchaLogin></CaptchaLogin>
  <UpdateApplicant :name="name" @submit="submit"></UpdateApplicant>
  <Auth></Auth>
</template>

<script setup>
/* eslint-disable */
import SoftDialog from './components/SoftDialog.vue'
import Question from './components/Question.vue'
import FeedBack from './components/FeedBack.vue'
import CaptchaLogin from './components/CaptchaLogin.vue'
import UpdateApplicant from './components/UpdateApplicant.vue'
import Auth from './components/Auth.vue'
import { saveCopyrightAccountToken, writeAnewFile } from './api/index'
import { computed, ref, onMounted, reactive } from "vue";
import axios from 'axios'
import Bus from "./js/Bus";
import crypto from 'crypto'
import { useStore } from 'vuex'
import { useRoute, useRouter } from "vue-router";
import { ElMessage, ElLoading, ElMessageBox } from 'element-plus'
const router = useRouter()
const route = useRoute()
const store = useStore()
const username = ref('')
const password = ref('')
const accountType = ref(0)
const subType = ref(0)
const captchaIns = ref()

const writeId = computed({
  get () {
    return store.state.writeId
  },
  set (val) {
    store.commit('updateWriteId', val)
  }
})

const captchaSubmit = (e) => {
  console.log(e);
  username.value = e.username
  password.value = e.password
  accountType.value = e.accountType
  subType.value = e.subType
  captchaIns.value && captchaIns.value.verify()
}

const initNECaptcha = () => {
  initNECaptchaWithFallback({
    element: '#captcha',
    captchaId: 'a7bef55d9fed4e36a1004f2f8926a617',
    width: '320px',
    mode: 'popup',
    apiVersion: 2,
    popupStyles: {
      position: 'fixed',
      top: '20%'
    },
    onClose: function() {
      captchaIns.value.refresh()
    },
    onVerify: function (err, data) {
      if (err) return // 当验证失败时，内部会自动refresh方法，无需手动再调用一次
      // 若成功拿到 validate，可向后端发送请求
      ajax2Server(data.validate)
    }
  }, function onload(instance) {
    captchaIns.value = instance
  }, function onerror(err) {
    console.warn(err)
  })
}

const ajax2Server = (validate) => {
  if (validate) {
    // showInfoRef.value.innerHTML = `
    // 已成功拿到参数：<br/>
    // {<br/>
    //   account: ${formState.account},<br/>
    //   password: ${formState.password},<br/>
    //   validate: ${validate}<br/>
    // }<br/>
    // 可以向后端发起请求了<br/>`
    let params = {
      accountType: accountType.value, //0 个人 1企业
      // subType: "SUB" //员工
      loginName: username.value,
      password: password.value,
      ticket: validate,
      veriftyType: "1",
    }
    if (subType.value === 0) {
      params.subType = 'SUB'
    }
    const loading = ElLoading.service({
      lock: true,
      text: '登录中...',
      background: 'rgba(0, 0, 0, 0.7)',
    })
    axios.post('https://gateway.ccopyright.com.cn/userServer/login/login/accountLogin', params).then(function (res) {
      if (res.data.returnCode === 'FAILED') {
        console.log(this);
        ElMessage.error(res.data.message)
        loading.close()
      } else if (res.data.returnCode === 'SUCCESS') {
        saveToken(res.data.data, loading)
      }
    })
    .catch(function (error) {
      console.log(error);
      loading.close()
    })
    .finally(function () {
      // always executed
      console.log();
    });
  }
}

const name = ref('')
const applicantId = ref('')

const saveToken = (data, loading) => {
  let params = JSON.parse(JSON.stringify(data))
  params.writeId = writeId.value
  params.authorizationKey = data.authorization_key
  params.authorizationToken = data.authorization_token
  if (subType.value === 0) {
    params.subType = 'SUB'
  } else {
    params.subType = ''
  }
  params.loginName = username.value
  params.password = password.value
  params.accountType = accountType.value
  saveCopyrightAccountToken(params).then(res => {
    loading.close()
    if (res.code === 415) {
      name.value = res.data.name
      applicantId.value = res.data.id
      store.commit('updateShowApplicant', true)
    } else if (res.code === 416) {
      ElMessage({
        showClose: true,
        message: res.message,
        type: 'error',
        duration: 5000
      })
    } else if (res.code === 417) {
      ElMessage({
        showClose: true,
        message: res.message,
        type: 'error',
        duration: 5000
      })
    } else {
      store.commit('updateCaptcha', false)
      console.log('route.path', route.path);
      if (route.path === '/development') {
        Bus.$emit('copyrightLoginDone', 1)
      } else if (route.path === '/printingMaterials') {
        Bus.$emit('copyrightLoginDone', 2)
      } else {
        Bus.$emit('copyrightLoginDone', 0)
      }
    }
  }).catch(()=> {
    loading.close()
  })
}

const submit = () => {
  console.log('app submit');
  writeAnewFile({
    applicant: name.value,
    ownerId: applicantId.value,
    writeId: writeId.value
  }).then(res => {
    console.log(res);
    store.commit('updateShowApplicant', false)
    store.commit('updateCaptcha', false)
    Bus.$emit('uploadTemplateSuccess', 1)
  })
}

onMounted(() => {
  Bus.$off('showVerify')
  Bus.$on('showVerify', (e) => {
    captchaSubmit(e)
  })
  initNECaptcha()
})

// export default {
//   name: 'App',
//   components: {
//     SoftDialog,
//     Question,
//     FeedBack,
//     CaptchaLogin
//   },
//   data() {
//     return {
//       username: '',
//       password: '',
//       captchaIns: null,
//     }
//   },
//   created(){
//   },
//   mounted() {
//     Bus.$off('showVerify')
//     Bus.$on('showVerify', (e) => {
//       this.captchaSubmit(e)
//     })
//     this.initNECaptcha()
//   },
//   methods:{
//     ...mapActions(['updateCaptcha']),
//     captchaSubmit(e) {
//       console.log(e);
//       this.username = e.username
//       this.password = e.password
//       this.captchaIns && this.captchaIns.verify()
//     },
//   }
// }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  margin: 0;
  padding: 0;
}

.el-dialog {
  margin: 20vh auto 50px !important
}

body {
  margin: 0;
  padding: 0;
  background: #FAFAFA
  /* background: #F2F2F2; */
}

.flex {
  display: flex;
}

.flex1 {
  flex: 1;
}

.text-center {
  align-items: center;
}
.text-start {
  align-items: flex-start !important;
}
.text-end {
  align-items: flex-end !important;
}
.flex-cloum {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>
