import axios from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router';
import { removeCookie, getCookie } from '../assets/js/cookie';
const crypto = require("crypto");


let isShow = false

let url = process.env.VUE_APP_ENV_NAME === 'dev' ? process.env.VUE_APP_API_URL : window.location.origin + process.env.VUE_APP_API_URL_PATH
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// create an axios instance
const service = axios.create({
  baseURL: url, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 120000 // request timeout
})
// request interceptor
service.interceptors.request.use(
  config => {
    const timestamp = new Date().getTime()
    let signStr = sign(timestamp.toString(), getUuid())
      // config.headers['Authorization'] = 'Bearer3 ' + signStr
    if (getCookie()) {
      config.headers['Token'] = getCookie()
    }
    config.headers['platform'] = 0
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

function getUuid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
}

function sign (timestampStr, uuid) {
  // console.log('签名：', timestampStr, uuid)
  let secret = "l8c2scudgvf3aavrkuohep4r6a16nj7s"
  let stringToSign = timestampStr + uuid;
  // console.log('sign', crypto)
  let mac = crypto.createHmac("sha256", secret);
  mac.update(stringToSign);
  let signData = mac.digest();
  let signStr = Buffer.from(signData).toString("base64");
  let stringToSignStr = Buffer.from(stringToSign).toString("base64");
  return signStr + stringToSignStr;
}



function showLogout() {
  removeCookie('yw')
  ElMessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
    confirmButtonText: '重新登录',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    router.replace({ path: '/login' })
  }).catch(() => {})
}

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = res.data.message || '接口异常'
    if (code === 401) {
      if (!isShow) {
        showLogout()
        isShow = true
        setTimeout(()=>{
          isShow = false
        }, 1500)
      }
      return Promise.reject('error')
    } else if (code === 413) {
      // console.log('版权中心token失效');
      return Promise.resolve(res.data)
    } else if (code === 414) {
      // console.log('上传文件失败');
      return Promise.resolve(res.data)
    } else if (code === 415) {
      // console.log('版权人信息不一致');
      return Promise.resolve(res.data)
    } else if (code === 416) {
      // console.log('未实名');
      return Promise.resolve(res.data)
    } else if (code === 417) {
      // console.log('无默认地址');
      return Promise.resolve(res.data)
    } else if (code === 418) { 
      // 418 该权益未扣费，且只有一个可以扣费的套餐；同时返回改套餐的id
      // 419 该权益未扣费，且有多个可以扣费的套餐，调用获取相关套餐的接口，选择套餐
      // 420 该权益未扣费，且用户没有相关可以扣费的套餐，提示用户购买相关套餐
      return Promise.resolve(res.data)
    } else if (code === 419) {
      return Promise.resolve(res.data)
    } else if (code === 420) {
      return Promise.resolve(res.data)
    } else if (code === 421) { //安装包下载中
      return Promise.resolve(res.data)
    } else if (code === 500) {
      ElMessage.error(msg)
      return Promise.reject(new Error(msg))
    } else if (code !== 200) {     
      // if (!res.request.responseURL.includes('/copyright/addInformation')) {
      ElMessage.error(msg)
      return Promise.reject('error')
    } else {
      return Promise.resolve(res.data)
    }
  },
  error => {
    let href = window.location.href
    if (href.indexOf('stop-service') === -1 && error.response.status === 502) {
      router.push({ path: '/stop-service' })
    }
    return Promise.reject(error)
  }
)

export default service
