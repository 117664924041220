<script setup>
/* eslint-disable */
  import { ElMessage } from 'element-plus'
  import { useStore } from 'vuex'
  import { useRouter } from "vue-router";
  import { ref, reactive, computed, watchEffect } from 'vue';
  import Bus from "../js/Bus";
  import axios from 'axios'
  import crypto from 'crypto'
  const show = computed({
    get () {
      return store.state.showCaptcha
    },
    set (val) {
      store.commit('updateCaptcha', val)
    }
  })

  const router = useRouter()
  const store = useStore()
  const loginFormRef = ref(null);
  const btnLoading = ref(false)
  const loginForm = reactive({
    username: '',
    password: '',
    accountType: 1 //0个人 1 企业
    // agree: false
  });

  const formRules = {
    username: [
      { required: true, message: '用户名不能为空', trigger: 'blur' },
    ],
    password: [
      { required: true, message: '密码不能为空', trigger: 'blur' },
    ]
  }
  const beforeClose = (done) => {
    store.commit('updateCaptcha', false)
    done()
  } 

  const cancel = () => {
    store.commit('updateCaptcha', false)
  }

  const subType = ref(1)

  const checked = ref(false)

  const login = () => {
    loginFormRef.value.validate((valid) => {
      if (!valid) return ElMessage.error('请填写登录信息后再进行登录操作！')
      if (!checked.value) {
        ElMessage.error('请阅读并同意授权协议')
        return
      }
      Bus.$emit('showVerify', {
        username: loginForm.username,
        password: getmd5(loginForm.password),
        accountType: loginForm.accountType,
        subType: subType.value
      })
    })
  }

  const toAuthNotice = () => {
    let baseUrl =  window.location.href.split('#')[0]
    let url = baseUrl + '#/noticeDetail?type=1'
    window.open(url, "_blank")
  }

  const getmd5 = (str) => {
    var a
    var md5 = crypto.createHash("md5")
    //update("中文", "utf8")
    md5.update(str)
    var a = md5.digest('hex');
    console.log(a)
    //47bce5c74f589f4867dbd57e9ca9f808
    return a
  }

  const accountTypeChange = (val) => {
    subType.value = 1
  }

  const linkToCopyright = () => {
    window.open('https://register.ccopyright.com.cn/register.html#/identity', "_blank")
  }

  watchEffect(() => {
    if (show.value) {
      subType.value = 1
      loginForm.username = ''
      loginForm.password = ''
      loginForm.accountType = 1
      console.log('watchEffect ca');
      if (loginFormRef.value) {
        loginFormRef.value.clearValidate()
      }
    }
  })
</script>

<template>
  <div class="captcha">
    <el-dialog v-model="show"
      style="width: 448px"
      :show-close="false"
      :beforeClose="beforeClose"
      :close-on-press-escape="false"
      :close-on-click-modal="false">
      <div>
        <el-form
          class="from-login"
          :model="loginForm"
          :rules="formRules"
          ref="loginFormRef"
          label-position="top"
          label-width="0" 
          status-icon>
          <el-form-item label="">
            <el-radio-group v-model="loginForm.accountType"  @change="accountTypeChange">
              <el-radio-button :label="1">机构用户</el-radio-button>
              <el-radio-button :label="0">个人用户</el-radio-button>
            </el-radio-group>
            <el-radio-group class="form-body" v-if="loginForm.accountType === 1" v-model="subType">
              <el-radio :label="1">管理员</el-radio>
              <el-radio :label="0">员工</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item class="form-body form-input" label="用户名" label-width="100"  prop="username">
            <el-input type="text" v-model="loginForm.username" placeholder="请输入用户名" autocomplete="off" ></el-input>
          </el-form-item>
          <el-form-item class="form-body form-input" label="密码" label-width="100" prop="password">
            <el-input type="password" show-password v-model="loginForm.password" @keyup.enter="login" autocomplete="new-password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <div class="btns">
            <el-button type="primary" :loading="btnLoading" :disabled="btnLoading" class="subBtn" @click="login">登录版权中心</el-button>
            <el-button class="cancelBtn" @click="cancel">取消</el-button>
          </div>
          <div class="link-info">若无账号，请前往版权中心<span class="link-span" @click="linkToCopyright">注册并完成实名认证</span></div>
          <div class="check-item">
            <el-checkbox v-model="checked" label="阅读并同意" size="large" />
            <span @click="toAuthNotice" class="sqxz">《授权协议》</span>
          </div>
        </el-form>
      </div>
      <template #header>
        <div class="dialog-header">
          
        </div>
      </template>
      <template #footer>
        <span class="dialog-footer">
        
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.captcha {
  :deep(.el-dialog) {
    .el-dialog__header {
      padding: 0;
      margin: 0;
    }
    .el-dialog__body {
      padding: 0;
    }
    .el-dialog__footer {
      padding: 0;
    }
    .form-body {
      padding:  0 48px;
    }
    .form-input {
      margin-top: 12px;
      input {
        height: 42px;
        background: #FFFFFF;
      }
    }
    .el-radio-group {
      width: 100%;
      display: flex;
      .is-active {
        .el-radio-button__inner {
          background: #FFFFFF !important;
          color: #6196FF !important;
        }
      }
      .el-radio-button {
        flex: 1;
        height: 53px;
        .el-radio-button__inner {
          background: #E8E8E8;
          border-radius: 0px 0px 0px 0px;
          border: none;
          width: 100%;
          height: 100%;
          font-weight: 400;
          font-size: 18px;
          color: #9E9E9E;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: none;
        }
      }
      .el-radio + .el-radio {
        margin-left: 80px;
      }
      .el-radio {
        margin-top: 48px;
      }
    }
    .link-info {
      text-align: center;
      padding: 16px 0 0;
      font-family: Source Han Sans, Source Han Sans;
      font-weight: 400;
      font-size: 14px;
      color: #9E9E9E;
      .title {
        font-size: 16px;
      }
      .link-span {
        color: #6196FF;
        cursor: pointer;
      }
    }
    .btns {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 28px 0 12px;
      .subBtn {
        width: 351px;
        height: 55px;
        background: #6196FF;
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        border-radius: 0;
      }
      .cancelBtn {
        width: 36px;
        height: 23px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 18px;
        color: #9E9E9E;
        margin-top: 16px;
        border: none;
      }
    }
    .check-item {
        margin-top: 16px; 
        padding-bottom: 16px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        label {
          height: 20px;
        }
        span {
          font-size: 14px;
        }
        .sqxz {
          color: #0073FF;
          cursor: pointer;
          height: 20px;
        }
      }
  }
}

</style>